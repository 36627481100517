import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["amount", "unit", "content"];

  async apply(e) {
    e.preventDefault();

    const batchId = this.element.dataset.id;
    const url = new URL(window.location.href);
    const page = url.searchParams.get('page');

    const res = await patch(`/apply_top_up/${batchId}`, {
      body: {
        ccb2b_coupon: {
          top_up_amount: this.amountTarget.value,
          top_up_unit: this.unitTarget.value,
        },
        page: page,
      },
    });

    if (res.ok) {
      this.contentTarget.innerHTML = await res.html;
    } else {
      alert("Failed to apply top-up to batch.")
    }
  }
}
